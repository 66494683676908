$.ajaxSetup({
    headers: {
        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
    }
});

$(document).on('submit', '#form-book', function(e) {
    //console.log('book');
    e.preventDefault();
    var form = $(this);

    $.ajax({
        headers: { 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'), 'X-Requested-With': 'XMLHttpRequest' },
        type: form.attr("method"),
        url: form.attr("action"),
        cache: false,
        dataType: "json",
        contentType: false,
        processData: false,
        data: new FormData(form[0]),
        beforeSend: function() {},
        success: function(data) {

            $('#book .form-wrap').html(data.message);

            setTimeout(function() {
                UIkit.modal('#book').hide();
            }, 10000);

            //console.log(data.data);
        },
        complete: function() {
            $('.form-wrap input').keypress(function(e) {
                $(this).removeClass('error');
            });

        },
        error: function(data) {
            $.each(data.responseJSON.errors, function(index, value) {
                //console.log(index);
                $('input[name="' + index + '"]').addClass('error');
                UIkit.notification({
                    message: value,
                    status: "danger",
                    pos: "bottom-right",
                    timeout: 4000
                });
            });
        }
    });

});

//Callback

$(document).on('submit', '#form-callback, #form-agent', function(e) {
    //console.log('callback');
    e.preventDefault();
    var form = $(this);


    $.ajax({
        type: form.attr("method"),
        url: form.attr("action"),
        cache: false,
        dataType: "json",
        contentType: false,
        processData: false,
        data: new FormData(form[0]),
        beforeSend: function() {},
        success: function(data) {
            $('#callback .form-wrap, #agent .form-wrap').html(data.message);
            setTimeout(function() {
                UIkit.modal('#callback').hide();
                UIkit.modal('#agent').hide();
            }, 10000);

        },
        complete: function() {

        },
        error: function(data) {
            $.each(data.responseJSON.errors, function(index, value) {
                UIkit.notification({
                    message: value,
                    status: "danger",
                    pos: "bottom-right",
                    timeout: 3000
                });
            });
        }
    });

});



//Subscribe

$(document).on('submit', '#form-subscribe', function(e) {

    e.preventDefault();
    var form = $(this);


    $.ajax({
        type: form.attr("method"),
        url: form.attr("action"),
        cache: false,
        dataType: "json",
        contentType: false,
        processData: false,
        data: new FormData(form[0]),
        beforeSend: function() {},
        success: function(data) {
            $('#subscribe .form-wrap').html(data.html);
            setTimeout(function() {
                UIkit.modal('#subscribe').hide();
            }, 10000);
        },
        complete: function() {

        },
        error: function(data) {
            $.each(data.responseJSON.errors, function(index, value) {
                UIkit.notification({
                    message: value,
                    status: "danger",
                    pos: "bottom-right",
                    timeout: 3000
                });
            });
        }
    });

});



//Subscribe

$(document).on('submit', '#form-connection', function(e) {

    e.preventDefault();
    var form = $(this);


    $.ajax({
        type: form.attr("method"),
        url: form.attr("action"),
        cache: false,
        dataType: "json",
        contentType: false,
        processData: false,
        data: new FormData(form[0]),
        beforeSend: function() {},
        success: function(data) {
            $('#connection .form-wrap').html(data.html);
            setTimeout(function() {
                UIkit.modal('#connection').hide();
            }, 10000);
        },
        complete: function() {

        },
        error: function(data) {
            $.each(data.responseJSON.errors, function(index, value) {
                UIkit.notification({
                    message: value,
                    status: "danger",
                    pos: "bottom-right",
                    timeout: 3000
                });
            });
        }
    });

});