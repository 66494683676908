require("./bootstrap");

$(window).on("load", function () {
  setTimeout(function () {
    $("body").removeAttr("style");
  }, 150);

  setTimeout(function () {
    $(".loading").fadeOut("300");
  }, 300);

  setTimeout(function () {
    $(".loading").remove();
  }, 600);
});

$(document).ready(function () {
  var index = $("#top-bar-media").find("li.active").data("idx");

  var sliderIndexNav = $("#slider-object-nav ul.uk-slider-items")
    .find("li.active")
    .data("idx");

  var indexSubnav = $("#list-nav-content").find("li.active").data("idx");

  $("#top-bar-media li").each(function () {
    UIkit.slider("#top-bar-media").show(index);
  });

  if ($("#slider-object-nav").length > 0) {
    UIkit.slider("#slider-object-nav").show(sliderIndexNav);
  }

  $("#list-nav-content li").each(function () {
    UIkit.slider("#list-nav-content").show(indexSubnav);
  });

  $.ajaxSetup({
    headers: {
      "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content"),
    },
  });

  $(".mask-phone").mask("+7 (999) 999-99-99", {
    placeholder: "+7 (___) ___-__-__",
  });

  window.scrollChess = function () {
    if ($(".list-checkerboard").length > 0) {
      const slider = document.querySelector(".list-checkerboard");
      let isDown = false;
      let startX;
      let scrollLeft;

      slider.addEventListener("mousedown", (e) => {
        isDown = true;
        slider.classList.add("active");
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
        slider.classList.remove("active");
      });
      slider.addEventListener("mousemove", (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
      });
      $(document).on("beforeshow", ".item-object", function () {});
      if (screen.width >= 960) {
        tippy(".kv", {
          content: "Загрузка...",
          onShow(instance) {
            $.ajax({
              url:
                "/novostrojki/item/" + instance.reference.dataset.id + "/info",
              type: "POST",
              success: function (data) {
                //console.log(instance);
                //.reference.attributes.data-id.nodeValue
                $(instance.popper).html(data.html);
                //$('#chess_item_' + instance.id).removeClass('uk-hidden');
              },
              error: function (error) {
                //console.log(error);
              },
            });
          },
          allowHTML: true,
          duration: 0,
          delay: 0,
          theme: "light",
          interactive: false,
          appendTo: () => document.body,
        });
      }

      $(".block-item-cx").each(function () {
        $(this)
          .parent()
          .css({ height: $(this).height(), width: $(this).width() + 50 });
      });
    }
  };
  scrollChess();

  function filterInput() {
    function formatComplex(opt) {
      var length = $('[name="complex_id[]"]').children(
        "option:selected"
      ).length;
      if (length > 1) {
        return opt.text + ",";
      } else {
        return opt.text;
      }
    }

    function formatComplexImg(opt) {
      if (!opt.id) {
        return opt.text;
      }
      var img = $(opt.element).attr("data-img");
      if (!img) {
        return opt.text;
      } else {
        var $opt = $(
          '<span class="complex_option" style="background-image: url(' +
            img +
            ')"></span> ' +
            opt.text +
            "</span>"
        );
        return $opt;
      }
    }

    $('[name="complex_id[]"]')
      .select2({
        closeOnSelect: false,
        placeholder: "Все объекты",
        allowHtml: true,
        allowClear: true,
        width: "resolve",
        dropdownAutoWidth: true,
        tags: true,
        dropdownCssClass: "complex_select",
        tokenSeparators: [",", " "],
        templateResult: formatComplexImg,
        templateSelection: formatComplex,
        minimumResultsForSearch: Infinity,
      })
      .on("select2:unselecting", function () {
        $(this).data("unselecting", true);
      })
      .on("select2:opening", function (e) {
        if ($(this).data("unselecting")) {
          $(this).removeData("unselecting");
          e.preventDefault();
        }
      });
    /*
        $('[name="complex_id[]"]').multiSelect({
            noneText: 'Все новостройки'
        });
*/
    $('#filter-form-item [name="flat_rooms_count[]"]').multiSelect({
      noneText: "Комнат",
      buttonHTML: '<span class="multi-select-button roomsCount">',
    });

    $('[name="stage"]')
      .select2({
        placeholder: "Стадия",
        allowClear: true,
        dropdownAutoWidth: true,
        dropdownCssClass: "stage_select",
        minimumResultsForSearch: Infinity,
      })
      .on("select2:unselecting", function () {
        $(this).data("unselecting", true);
      })
      .on("select2:opening", function (e) {
        if ($(this).data("unselecting")) {
          $(this).removeData("unselecting");
          e.preventDefault();
        }
      });

    $('[name="category_id"]')
      .select2({
        placeholder: "Все категории",
        allowClear: false,
        dropdownAutoWidth: true,
        dropdownCssClass: "category_select",
        minimumResultsForSearch: Infinity,
      })
      .on("select2:unselecting", function () {
        $(this).data("unselecting", true);
      })
      .on("select2:opening", function (e) {
        if ($(this).data("unselecting")) {
          $(this).removeData("unselecting");
          e.preventDefault();
        }
      });

    $('#filter-form-item [name="price"]')
      .select2({
        allowClear: false,
        dropdownAutoWidth: true,
        minimumResultsForSearch: Infinity,
      })
      .on("select2:unselecting", function () {
        $(this).data("unselecting", true);
      })
      .on("select2:opening", function (e) {
        if ($(this).data("unselecting")) {
          $(this).removeData("unselecting");
          e.preventDefault();
        }
      });
  }

  filterInput();

  $(".partner").hover(function () {
    if ($(this).hasClass("start-block")) {
      $(this).next().toggleClass("active");
    } else {
      $(this).prev().toggleClass("active");
      $(this).next().toggleClass("active");
    }
  });

  $(document).on("click", ".favorite-form span", function (e) {
    e.preventDefault();
    $(this).parents().toggleClass("active");
    var form = $(this).parents();
    var form_data = $(this).parents().serialize();

    $.ajax({
      type: form.attr("method"),
      url: form.attr("action"),
      cache: false,
      dataType: "json",
      contentType: false,
      processData: false,
      data: form_data,
      beforeSend: function () {},
      success: function (data) {
        //console.log(data);
        $("#favorite").html(data.html);
      },
      complete: function () {},
      error: function (error) {
        //console.log(data);
      },
    });
  });
  /*
        $(document).on('click', '.img-type li', function(e) {
            e.preventDefault();
            $('.img-type li').removeClass('active');
            $(this).addClass('active');
        });
    */

  $(document).on(
    {
      mouseenter: function () {
        $(this).find("span").fadeToggle(150);
      },
      mouseleave: function () {
        $(this).find("span").fadeToggle(150);
      },
    },
    ".whatsapp"
  );

  $(document).on("click", ".whatsapp", function (e) {
    e.preventDefault();
    UIkit.modal("#whatsappDialog").show();
  });

  $(document).on("click", ".whatsapp-link", function () {
    var link = $(this)
      .parent()
      .find(".layout-block-img")
      .children("a")
      .attr("href");
    $('#whatsappDialog input[name="whatsapp-text"]').val(link);
  });

  function sendToWhatsapp(text, phone) {
    if (text !== "" && phone !== "") {
      text = encodeURIComponent(text);
      let url = `https://api.whatsapp.com/send?phone=${phone}&text=${text}`;
      window.open(url);
      UIkit.modal(".uk-modal").hide();
    }
  }
  $(document).on("click", "#submit-whatsapp", function (e) {
    e.preventDefault();
    let phoneNumber = $('[name="whatsapp-phone"]').val();
    let text = $('[name="whatsapp-text"]').val();
    sendToWhatsapp(text, phoneNumber);
    UIkit.modal("#whatsappDialog").hide();
  });

  $(document).on("click", ".property-list a.readmore", function (e) {
    e.preventDefault();
    $("#readmore-property-list").slideToggle(200);
    if ($(this).text() == "Все параметры") {
      $(this).text("Скрыть");
    } else {
      $(this).text("Все параметры");
    }
  });

  /*
        $(document).on('click', '#ajax-filter-top', function(e) {
            e.preventDefault();

            $('#layout').removeClass('uk-padding-remove-top');
            $('#reset-filter').html('Скрыть фильтр <span class="material-icons material-icons-outlined">filter_list</span>');
            $('.top-bar-breadcrumb').hide();
            $('#filter-result').fadeIn(100).css('opacity', 1);


        });
    */
  $(document).on("click", "#hidden-filter", function (e) {
    e.preventDefault();
    $(".top-bar-breadcrumb").show();
    $("#layout").addClass("uk-padding-remove-top");
    $("#filter-result").fadeOut(100).css("opacity", 0);
    $("#filter-result").html("");
  });

  function loadFilter() {
    $(document).on("change", '#filter select[name="price"]', function (e) {
      e.preventDefault();
      var value = $(this).find("option:selected").val();

      var price_from = $('input[name="price_from"]').attr("placeholder");
      var price_to = $('input[name="price_to"]').attr("placeholder");
      var price_from_data = $('input[name="price_from"]').data("price");
      var price_to_data = $('input[name="price_to"]').data("price");

      $('input[name="price_from"]')
        .data("price", price_from)
        .attr("placeholder", price_from_data)
        .trigger("change");
      $('input[name="price_to"]')
        .data("price", price_to)
        .attr("placeholder", price_to_data)
        .trigger("change");
    });
  }

  loadFilter();

  $(document).on("click", "#filter-button", function (e) {
    e.preventDefault();

    var form = $("#filter-form-item");
    var form_data = form
      .serialize()
      .replace(/[^&]+=&/g, "")
      .replace(/&[^&]+=$/g, "");

    //console.log(form_data);

    var pageUrl = "?" + form_data;
    window.history.replaceState(form_data, "", pageUrl);

    if ($(this).hasClass("uk-button-get")) {
      window.location.href = form.attr("action") + pageUrl;
    } else {
      $.ajax({
        type: form.attr("method"),
        url: form.attr("action"),
        cache: false,
        async: true,
        dataType: "json",
        contentType: false,
        processData: false,
        data: form_data,
        beforeSend: function () {
          $("#list-item-result").prepend(
            '<div class="preloader"><span></span></div>'
          );
        },
        success: function (data) {
          if (data.html) {
            $("#list-item-result").html(data.html);
            $("#nav-content").html(data.nav);
            $(".header-pagetitle h2").html(data.title);
            $("html").removeClass("uk-offcanvas-page").removeAttr("style");
            $("body").removeClass("uk-offcanvas-container").removeAttr("style");
            wrap_filter();
            UIkit.modal("#modal-more-parameters").hide();
          }
          $("#filter-result .wrap-filter-block").parent().html(data.filter);
          filterInput();
          if (data.total > 0) {
            $(".informer-filter").html("<span>" + data.total + "</span>");
          } else {
            $(".informer-filter").html("");
          }
          //console.log(data.total);
        },
        complete: function () {},
        error: function (error) {},
      });
      return false;
    }
  });

  if ($(".property-list >.uk-flex").length > 4) {
    $(".property-list .readmore").removeClass("uk-hidden");
  }

  $(document).on("click", "#reset-filter", function () {
    window.location.href = window.history.replaceState(
      null,
      null,
      window.location.pathname
    );
    location.href = window.location.href;
    //console.log(window.location.href);
    return false;
  });

  $(document).on("click", "#ajax-filter-top", function (e) {
    e.preventDefault();

    var form = $("#filter-form-item");

    $.ajax({
      type: "POST",
      url: "/filter",
      cache: false,
      async: true,
      dataType: "json",
      contentType: false,
      processData: false,
      data: new FormData(form[0]),
      beforeSend: function () {
        $("#ajax-filter-top.hidden")
          .addClass("active")
          .removeClass("hidden")
          .find("span")
          .html("sync");
      },
      success: function (data) {
        $("#filter-result").html(data.html);
      },
      complete: function () {
        $("#layout").removeClass("uk-padding-remove-top");
        //$('#hidden-filter').html('Скрыть фильтр <span class="material-icons material-icons-outlined">filter_list</span>');
        $(".top-bar-breadcrumb").hide();
        $("#filter-result").fadeIn(100).css("opacity", 1);
        filterInput();
        $("#filter-form-item").attr(
          "action",
          "//" + document.domain + "/novostrojki/items"
        );

        $("#filter-button").addClass("uk-button-get");
        $("#ajax-filter-top")
          .removeClass("active")
          .addClass("hidden")
          .find("span")
          .html("filter_list");
      },
      error: function (error) {
        //console.log(data);
      },
    });
  });

  $(document).on("click", "#filter-right", function (e) {
    e.preventDefault();

    var form = $("#filter-form-item");

    if ($("#filter form#filter-form-item").length == 0) {
      $.ajax({
        type: "POST",
        url: "/filter",
        cache: false,
        async: true,
        dataType: "json",
        contentType: false,
        processData: false,
        data: new FormData(form[0]),
        beforeSend: function () {},
        success: function (data) {
          $("#filter-result").html(data.html);

          $("html").addClass("uk-offcanvas-page").css("overflow-y", "hidden");
          $("body").addClass("uk-offcanvas-container").css({
            "overflow-y": "scroll",
            "touch-action": "pan-y pinch-zoom",
          });
          $("#filter-result .wrap-filter-block").addClass("open");

          setTimeout(function () {
            $("#filter-result .wrap-filter-block").addClass("after");
          }, 150);
        },
        complete: function () {
          if ($("#filter .select2").length == 0) {
            filterInput();
          }
        },
        error: function (error) {
          //console.log(data);
        },
      });
    } else {
      $("html").addClass("uk-offcanvas-page").css("overflow-y", "hidden");
      $("body")
        .addClass("uk-offcanvas-container")
        .css({ "overflow-y": "scroll", "touch-action": "pan-y pinch-zoom" });
      $("#filter-result .wrap-filter-block").addClass("open");

      setTimeout(function () {
        $("#filter-result .wrap-filter-block").addClass("after");
      }, 150);
    }
  });

  var wrap_filter = function () {
    $("html").removeClass("uk-offcanvas-page").removeAttr("style");
    $("body").removeClass("uk-offcanvas-container").removeAttr("style");
    $("#filter-result .wrap-filter-block").removeClass("after");
    setTimeout(function () {
      $("#filter-result .wrap-filter-block").removeClass("open");
    }, 300);
  };

  $(document).on("click", "#filter .uk-close", function (e) {
    e.preventDefault();
    wrap_filter();
  });

  function numberWithSpaces(x) {
    return String(x.replace(/[^0-9.]/g, "")).replace(
      /\B(?=(\d{3})+(?!\d))/g,
      " "
    );
  }

  $('input[name="price_from"], input[name="price_to"]').keyup(function () {
    var price_from = $('input[name="price_from"]').val();
    $('input[name="price_from"]').val(numberWithSpaces(price_from));

    var price_to = $('input[name="price_to"]').val();
    $('input[name="price_to"]').val(numberWithSpaces(price_to));
  });
  /*
        var options = {
            horizontal: 1,
            scrollBy: 0,
            smart: 1,
            scrollBar: ('.scrollbar'),
            itemSelector: '.slidee-item',
            itemNav: 'basic',
            mouseDragging: 1,
            touchDragging: 1,
            releaseSwing: 1,
            startAt: 0,
            speed: 800,
            elasticBounds: 0,
            dragHandle: 1,
            dynamicHandle: 1,
            scrollTrap: 1
        };
        $('.slidee').sly(options);

    */

  $(document).on(
    "click",
    ".item-object, #object-item-result .floors-list li a.floors-list-item",
    function (e) {
      e.preventDefault();
      $(".kv").removeClass("active");
      $(this).addClass("active");

      $('div[data-id="' + $(this).data("id") + '"]').addClass("active");

      var id = $(this).data("id");

      $.ajax({
        type: "POST",
        url: "/object-item-result",
        cache: true,
        async: true,
        dataType: "json",
        data: { id: id },
        beforeSend: function () {
          $("#object-item-result").css("opacity", 0);
        },
        success: function (data) {
          $("#object-item-result").html(data.html);
        },
        complete: function () {
          $("#object-item-result").css("opacity", 1);
          $("#loadBlockObject").addClass("open");
          $(".mask-phone").mask("+7 (999) 999-99-99", {
            placeholder: "+7 (___) ___-__-__",
          });
        },
        error: function (error) {},
      });
    }
  );

  $(document).on("click", "#loadBlockObject #close", function () {
    $("#loadBlockObject").removeClass("open");
    $("body").removeClass("uk-overflow-hidden");
  });

  var getUrlParameter = function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
      sURLVariables = sPageURL.split("&"),
      sParameterName,
      i;
    for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split("=");
      if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : sParameterName[1];
      }
    }
  };

  $(document).on("click", "a.more", function (e) {
    e.preventDefault();
    var url = $(this).attr("href");
    var page = $(this).search;
    var thisParent = $(this).parent().parent();

    window.history.pushState(null, null, url);

    $.ajax({
      type: "GET",
      url: url,
      cache: true,
      async: true,
      dataType: "json",
      data: { page: page },
      beforeSend: function () {
        $("nav.uk-margin-top").remove();
        $("a.more")
          .addClass("active")
          .html(
            '<span class="material-icons material-icons-outlined">sync</span>'
          );
      },
      success: function (data) {
        //console.log(data.page);
        //$('#object-item-result').html(data.html);
        $("#list-item-result").append(data.html);
      },
      complete: function () {
        $("a.more.active").remove();
        thisParent.remove();
      },
      error: function (error) {},
    });
    return false;
  });

  if ($("body .content_block").length > 0) {
    var content_block_height = $(".content_block").height();
    if (content_block_height > 250) {
      $(".content_block").toggleClass("hide");
      if ($(".content_block").hasClass("hide")) {
        $(".content_toggle").html(
          '<span class="material-icons-outlined">expand_more</span>'
        );
      } else {
        $(".content_toggle").html(
          '<span class="material-icons-outlined">expand_less</span>'
        );
      }
    }
  }

  $(".content_toggle").click(function (e) {
    e.preventDefault();
    $(".content_block").toggleClass("hide");
    if ($(".content_block").hasClass("hide")) {
      $(".content_toggle").html(
        '<span class="material-icons-outlined">expand_more</span>'
      );
    } else {
      $(".content_toggle").html(
        '<span class="material-icons-outlined">expand_less</span>'
      );
    }
    return false;
  });
});

$(function () {
  $(".scroll-bar-wrap-top").on("scroll", function (e) {
    $(".scroll-bar-wrap").scrollLeft($(".scroll-bar-wrap-top").scrollLeft());
  });
  $(".scroll-bar-wrap").on("scroll", function (e) {
    $(".scroll-bar-wrap-top").scrollLeft($(".scroll-bar-wrap").scrollLeft());
  });
});
$(window).on("load", function (e) {
  $(".scroll-bar-wrap-top-thumb").width($("table").width());
  $(".scroll-bar-wrap-thumb").width($("table").width());
});

$(window).resize(function () {
  if (window.innerWidth <= 1260) {
    $(".wrap-filter-block").detach().prependTo("#filter-result");
  }
});
if (window.innerWidth <= 1260) {
  $(".wrap-filter-block").detach().prependTo("#filter-result");
}
